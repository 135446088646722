@supports (transition: initial) {
    .img-container {
        transition: opacity 0.3s;
    }
}

.portfolioCard {
    margin-bottom: .2rem;
    cursor: pointer;
}

.portfolioCardWrapper {
    max-width: 92%;
    margin-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (min-width: 992px) {
    .portfolioCardWrapper:hover > .img-container:not(:hover) {
        opacity: 0.5;
        
    }
}
